/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  Container,
  Card,
  CardTitle,
  Row,
  Col,
  CardHeader,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  Button,
} from 'reactstrap';
import omit from 'lodash.omit';
import * as yup from 'yup';
import { Form } from '@availity/form';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { upsertVendorLayerMutation } from '@/graphql/mutations';
import { useNetwork, useAdmin, usePageLoader, useNotifications } from '@/hooks';
import { Vars } from '@/utils';
import {
  vendorCampaignManyQuery,
  vendorLayerNodeQuery,
} from '@/graphql/queries';
import { BackButton } from '@/components';
import { useFormikContext } from 'formik';
import usePriceTierFromVendorLayer from '@/hooks/usePriceTierFromVendorLayer';
import TabHeader from './TabHeader';
import GeneralTab from './tabs/General';
import CategoriesTab from './tabs/Categories';
import FiltersTab from './tabs/Filters'; // regionVerification, // priceVerification, // phoneVerification, // emailVerification, // tcpaVerification,
import LiveTransfersTab from './tabs/LiveTransfers';
import AddonsTab from './tabs/Addons';
import OtherTab from './tabs/Other';
import UploadTab from './tabs/Uploads';
import AttributesTab from './tabs/Attributes/index';

const convertDelaySecondsForCallToSeconds = (
  conversionMetric: string,
  timeInterval: number
): number => {
  switch (conversionMetric) {
    case 'minute':
    case 'minutes':
      return timeInterval * 60;
    case 'hour':
    case 'hours':
      return timeInterval * 60 * 60;
    default:
      return timeInterval;
  }
};

const DEFAULTS = {
  acceptsTermsAndConditions: false,
  autoProcess: false,
  autoProcessThreshold: 1000,
  description: '',
  enhanceProfile: false,
  filters: [
    // tcpaVerification,
    // emailVerification,
    // phoneVerification,
    // priceVerification,
    // regionVerification,
  ],
  attributes: [],
  postAttributes: [],
  pingAttributes: [],
  fixedBidPrice: 0,
  liveTransferAnswerTime: 10,
  liveTransferBatchSize: 15,
  liveTransferBatchCycleCount: 2,
  liveTransferDelaySecondsForCall: 300,
  liveTransferDoNotOverflow: false,
  liveTransferDropCall: false,
  liveTransferEmail: '',
  liveTransferIntroGreeting: true,
  liveTransferOverflowRedirectCall: false,
  liveTransferPhoneNumber: '',
  liveTransferRejectNoDataCalls: false,
  liveTransferTierID: '',
  liveTransferTimeConversion: 'seconds',
  liveTransferVoiceMailUrl:
    'https://live-transfer-urls.s3.amazonaws.com/defaults/voicemail.mp3',
  liveTransferVoip: '',
  liveTransferNotificationsOnly: false,
  liveTransferWaitUrl:
    'https://live-transfer-urls.s3.amazonaws.com/defaults/phone_dial.mp3',
  liveTransfersEnabled: false,
  liveTransferEarlyHangUpLayerID: '',
  phoneLookUp: 'areaCode',
  apiType: 'direct',
  hasBudget: false,
  pingPostEnabled: false,
  pingPostType: 'fixed',
  skipGTBDuplicate: false,
  refundEligible: false,
  refundMaxAge: 30,
  refundMaxAgeIncrement: 'day',
  refundMaxAgeDateType: 'soldDate',
  tags: [],
  rejectDisconnected: false,
  rejectDNC: false,
  rejectDuplicates: false,
  vendorSourceCampaign: '',
  vendorPrice: 0,
  maxDemandUnsoldAccepted: 0,
  splitCount: 0,
  vendorSourceCampaignMapArray: [''],
  vendor: undefined,
  vendorCampaigns: [],
  defaultVendorCampaign: {},
  isCampaignDestination: false,
  skipActiveDemandSubmit: false,
  overideDuplicateChecks: false,
  allowMLOverride: false,
  allowMLearning: false,
  smartPriceAcceptence: false,
  dupPriceThreashold: 0,
  dupPriceThreasholdLookBack: 30,
  allowAPOnPing: false,
  allowSlices: false,
  allowSplits: false,
  rejectTresstle: false,
  tresstleScoreMin: 70,
  verifyDestination: false,
  attributeRanges: {
    age: {
      highestBound: 64,
      lowestBound: 20,
      nextSegment: 5,
    },
    income: {
      highestBound: 249999,
      lowestBound: 20000,
      nextSegment: 10000,
    },
    household: {
      highestBound: 14,
      lowestBound: 1,
      nextSegment: 2,
    },
  },
  kindOfLead: 'uknown',
  isUpdateEligible: false,
  delaySecondsForUpdate: 0,
  mlTargetConversionRate: 0.07,
  allowMLPriceOverride: false,
  mlTargetMinPrice: 0,
  mlTargetMaxPrice: 100,
  mlAggressionConversionLevel: 0,
  mlAggressionPriceLevel: 0,
  useEventsMLModel: false,
  minAcceptenceBid: 0,
};

const intRangesYup = yup.object().shape({
  nextSegment: yup.number().min(2, 'Cannot set range less than 2.'),
  lowestBound: yup.number().min(0, 'Cannot be negative.'),
  highestBound: yup
    .number()
    .test(
      'greater-than',
      'Must be greater than lowest bound.',
      (highestBound, schema) => {
        const lowestBound = schema.from?.[0].value.lowestBound;
        return highestBound > lowestBound;
      }
    )
    .test(
      'no-remainder',
      'Range must divide evenly into highest bound.',
      (highestBound, schema) => {
        const lowestBound = schema.from?.[0].value.lowestBound;
        const nextSegment = schema.from?.[0].value.nextSegment;
        const availableValues = [];
        if (nextSegment < 1) return false;
        for (
          let step = lowestBound;
          step <= highestBound;
          step += nextSegment
        ) {
          const highBound = step + nextSegment - 1;
          availableValues.push(highBound);
          if (highBound > highestBound) break;
        }
        return availableValues.includes(highestBound);
      }
    ),
});

const SubmitErrors: React.FC = () => {
  const { submitCount, isValid } = useFormikContext();
  const { create: createNotification } = useNotifications();
  const [lastHandled, setLastHandled] = useState(0);

  useEffect(() => {
    if (submitCount > lastHandled && !isValid) {
      createNotification('danger', 'Please fix error highlighted tabs.');
      setLastHandled(submitCount);
    }
  }, [createNotification, isValid, lastHandled, submitCount]);

  return null;
};

const MIN_TIMEOUT = 5 * 1000;

const DetailView: React.FC<RouteComponentProps<{
  id: string;
}>> = ({ match, history }) => {
  const { network } = useNetwork();
  const { role } = useAdmin();
  const [activeTab, setActiveTab] = useState('connection');
  const {
    data: vendorLayerData,
    loading: loadingVendorLayer,
    refetch,
  } = useQuery(vendorLayerNodeQuery, {
    fetchPolicy: 'network-only',
    variables: {
      id: match.params.id,
    },
    skip: match.params.id === 'new',
  });
  const vendorLayer = vendorLayerData?.node;
  const isNew = !vendorLayer;
  const { start, complete } = usePageLoader();
  const { tiers } = usePriceTierFromVendorLayer(match.params.id);
  const {
    data: vCData,
    refetch: refetchVendorCampaigns,
    loading: loadingCampaigns,
  } = useQuery(vendorCampaignManyQuery, {
    variables: {
      networkID: network?.networkID,
      layerID: vendorLayer?.layerID,
    },
    skip: match.params.id === 'new',
  });
  const vendorLayerCampaigns = vCData?.vendorCampaignMany || [];

  const { create: creatNotification } = useNotifications();

  const [createOrUpdate] = useMutation(upsertVendorLayerMutation, {
    onCompleted: ({ vendorLayerUpsertOne }) => {
      setTimeout(async () => {
        if (!isNew) {
          await refetchVendorCampaigns();
          await refetch({
            id: vendorLayerUpsertOne.id || vendorLayer.id,
          });
        } else {
          history.push('/lead-sources');
        }
        creatNotification(
          'success',
          `Successfully ${isNew ? 'created' : 'updated'} Lead Source. ${
            isNew ? 'Assigned To Overflow Tier.' : ''
          }`,
          MIN_TIMEOUT,
          `${isNew ? 'create' : 'update'}-${vendorLayerUpsertOne.id}`
        );
        complete();
      }, 1000);
    },
    onError: (error) => {
      creatNotification(
        'error',
        `Failed to ${isNew ? 'create' : 'update'} Lead Source: ${error.message}`
      );
    },
  });

  // if we dont have a overflow price tier ID then this is not configured yet
  useEffect(() => {
    if (!network?.overflowPriceTierID) {
      history.push('/');
    }
  }, [network, history]);

  useEffect(() => {
    if (
      !loadingVendorLayer &&
      ((vendorLayer?.networkID &&
        vendorLayer.networkID !== network?.networkID) ||
        (!vendorLayer && match.params.id !== 'new'))
    ) {
      history.push('/lead-sources');
    }
  }, [history, loadingVendorLayer, network, vendorLayer]);

  if (loadingVendorLayer || loadingCampaigns) {
    return null;
  }

  const getDelaySecondsForCall = () => {
    const secondsDelay =
      vendorLayerCampaigns.find((vc: any) => vc.mpVendorCampaignID)
        ?.delaySecondsForCall ?? 300;

    if (secondsDelay >= 3600) {
      return {
        liveTransferDelaySecondsForCall: (secondsDelay / 3600).toFixed(1),
        liveTransferTimeConversion: secondsDelay === 3600 ? 'hour ' : 'hours',
      };
    }
    if (secondsDelay >= 60) {
      return {
        liveTransferDelaySecondsForCall: (secondsDelay / 60).toFixed(1),
        liveTransferTimeConversion: secondsDelay === 60 ? 'minute' : 'minutes',
      };
    }
    return {
      liveTransferDelaySecondsForCall: secondsDelay,
      liveTransferTimeConversion: secondsDelay === 1 ? 'second' : 'seconds',
    };
  };

  const handledVendorCampaigns = vendorLayerCampaigns
    ?.map((vC: any) => ({
      ...vC,
      categoryFields: vC.campaignName
        .split(' ')
        .map((field: string) => decodeURI(field)),
      dynamicPricingEnabled: !!vC?.dynamicPricingEnabled,
      defaultPrice: vC?.defaultPrice || 0,
      defaultPercentage: vC?.defaultPercentage || 0,
      apBidEnhancementCategoryOveride: !!vC?.apBidEnhancementCategoryOveride,
      overrideMLPrice: !!vC?.overrideMLPrice,
      overrideMaxMLPrice: !!vC?.overrideMaxMLPrice,
    }))
    ?.sort((a1: any) =>
      a1.campaignName === vendorLayer.vendorSourceCampaign ? 1 : 0
    )
    .filter((vC: any) => !vC?.default);

  return (
    <Container>
      <Form
        initialValues={
          isNew
            ? {
                ...DEFAULTS,
                verticalType: network?.verticalType || '',
                liveTransferScript: vendorLayer?.liveTransferScript || '',
                brokerID: network?.brokerID,
                apBidEnhancmentEnabled:
                  vendorLayer?.apBidEnhancmentEnabled || false,
                apSettings: vendorLayer?.apSettings || {
                  apBidEnhancmentDynamic: true,
                  apPercentageEnabled: true,
                  apWPMMin: 1,
                  apWPMMax: 200,
                  apKPMMin: 1,
                  apKPMMax: 500,
                  apBidPrice: 0,
                  apBidPercentage: 0,
                },
                maxSpendPerHour: 0,
                maxSpend: 0,
              }
            : {
                ...DEFAULTS,
                // Base
                layerID: vendorLayer.layerID,
                vendorSourceCampaign: vendorLayer?.vendorSourceCampaign,
                vendor: vendorLayer.vendor,
                description: vendorLayer.description || '',
                tags: vendorLayer.tags || [],
                verticalType: vendorLayer.verticalType,
                kindOfLead: vendorLayer.kindOfLead || 'uknown',
                brokerID: vendorLayer.brokerID || network?.brokerID,
                vendorPrice: vendorLayer.vendorPrice?.toFixed(2) || 0,
                maxDemandUnsoldAccepted:
                  vendorLayer.maxDemandUnsoldAccepted || 0,
                splitCount: vendorLayer.splitCount || 0,
                isUpdateEligible: vendorLayer.isUpdateEligible || false,
                delaySecondsForUpdate: vendorLayer.delaySecondsForUpdate || 0,
                mlTargetConversionRate:
                  vendorLayer.mlTargetConversionRate || 0.07,
                mlProbablityIncreasePercentage:
                  vendorLayer.mlProbablityIncreasePercentage || 0,
                mlProbablityIncreasePerLead:
                  vendorLayer.mlProbablityIncreasePerLead || 0,
                mlBeforeThreashold: vendorLayer.mlBeforeThreashold || false,
                allowMLPriceOverride: vendorLayer.allowMLPriceOverride || false,
                mlTargetMinPrice: vendorLayer.mlTargetMinPrice || 0,
                mlTargetMaxPrice: vendorLayer.mlTargetMaxPrice || 100,
                minAcceptenceBid: vendorLayer.minAcceptenceBid || 0,
                mlAggressionConversionLevel:
                  vendorLayer.mlAggressionConversionLevel || 0,
                mlAggressionPriceLevel: vendorLayer.mlAggressionPriceLevel || 0,
                useEventsMLModel: vendorLayer.useEventsMLModel || false,
                // Ping Post
                fixedBidPrice: vendorLayer.fixedBidPrice || 0, // TODO
                apiType: vendorLayer.apiType || 'direct',
                hasBudget: vendorLayer.maxSpendPerHour > 0,
                maxSpendPerHour: vendorLayer.maxSpendPerHour || 0,
                maxSpend: vendorLayer.maxSpend || 0,
                isCampaignDestination:
                  vendorLayer.isCampaignDestination || false,
                skipActiveDemandSubmit:
                  vendorLayer.skipActiveDemandSubmit || false,
                overideDuplicateChecks:
                  vendorLayer.overideDuplicateChecks || false,
                allowMLOverride: vendorLayer.allowMLOverride || false,
                allowMLearning:
                  vendorLayer.allowMLearning === undefined
                    ? false
                    : vendorLayer.allowMLearning,
                smartPriceAcceptence: vendorLayer.smartPriceAcceptence || false,
                dupPriceThreashold: vendorLayer.dupPriceThreashold || 0,
                dupPriceThreasholdLookBack:
                  vendorLayer.dupPriceThreasholdLookBack || 30,
                allowAPOnPing: vendorLayer?.allowAPOnPing || false,
                overrideBlockedAttributeFields:
                  vendorLayer?.overrideBlockedAttributeFields,
                overrideBlockedValues: vendorLayer?.overrideBlockedValues,
                crossNetworkLayers: vendorLayer?.crossNetworkLayers,
                allowSlices: vendorLayer?.allowSlices || false,
                allowSplits: vendorLayer?.allowSplits || false,
                rejectTresstle: vendorLayer?.rejectTresstle || false,
                tresstleScoreMin: vendorLayer?.tresstleScoreMin || 70,
                pingPostEnabled: vendorLayer.pingPostEnabled || false,
                skipGTBDuplicate: vendorLayer.skipGTBDuplicate || false,
                pingPostType: vendorLayer.pingPostType || 'fixed',
                // Block/Enhance
                enhanceProfile: vendorLayer.enhanceProfile,
                rejectDisconnected: vendorLayer.rejectDisconnected,
                rejectDNC: vendorLayer.rejectDNC,
                rejectDuplicates: vendorLayer.rejectDuplicates,
                refundEligible:
                  typeof vendorLayer.refundEligible === 'boolean'
                    ? vendorLayer.refundEligible
                    : true,
                refundMaxAge: vendorLayer.refundMaxAge,
                refundMaxAgeIncrement: vendorLayer.refundMaxAgeIncrement,
                refundMaxAgeDateType: vendorLayer.refundMaxAgeDateType,
                verifyDestination: vendorLayer.verifyDestination,
                // Filters
                filters:
                  vendorLayer.filters
                    .filter((f: any) => {
                      const { settings = {} } = f;
                      const { isCopy = false } = settings || {};
                      return !isCopy;
                    })
                    .map((filter: any) => {
                      if (filter.type !== 'STATIC') return filter;
                      return {
                        ...filter,
                        values: filter.values?.map((i: string) => ({
                          label: i,
                          value: i,
                        })),
                      };
                    }) || [],
                postAttributes: vendorLayer?.postAttributes,
                pingAttributes: vendorLayer?.pingAttributes,
                acceptsTermsAndConditions: false,
                attributeRanges: vendorLayer?.attributeRanges,
                // Categories
                vendorSourceCampaignMapArray: vendorLayer?.vendorSourceCampaignMap?.split(
                  ':'
                ) || [''],
                vendorCampaigns: handledVendorCampaigns || [],
                defaultVendorCampaign: vendorLayerCampaigns.filter(
                  (vC: any) => vC?.default
                )[0],
                // Auto Process
                autoProcess: vendorLayer.autoProcess || false,
                autoProcessThreshold: vendorLayer.autoProcessThreshold || 1000,
                categoryMappingEnabled: !!vendorLayer?.vendorSourceCampaignMap,
                // Live transfers
                liveTransferAnswerTime: vendorLayer?.liveTransferAnswerTime,
                liveTransferBatchSize: vendorLayer?.liveTransferBatchSize,
                liveTransferBatchCycleCount:
                  vendorLayer?.liveTransferBatchCycleCount,
                liveTransferDelaySecondsForCall: getDelaySecondsForCall()
                  .liveTransferDelaySecondsForCall,
                liveTransferDoNotOverflow:
                  vendorLayerCampaigns.find((vc: any) => vc.mpVendorCampaignID)
                    ?.doNotOverflow ?? false,
                liveTransferDropCall: !!vendorLayer?.liveTransferDropCall,
                liveTransferEmail: vendorLayer?.liveTransferEmail || '',
                liveTransferOverflowRedirectCall: !!vendorLayer.liveTransferOverflowRedirectCall,
                liveTransferNotificationsOnly: !!vendorLayer.liveTransferNotificationsOnly,
                liveTransferPhoneNumber: vendorLayer?.liveTransferPhoneNumber,
                liveTransferRejectNoDataCalls:
                  vendorLayer?.liveTransferRejectNoDataCalls || false,
                liveTransferTierID: vendorLayer?.liveTransferTierID || '',
                liveTransferTimeConversion: getDelaySecondsForCall()
                  .liveTransferTimeConversion,
                liveTransferVoiceMailUrl:
                  vendorLayer?.liveTransferVoiceMailUrl ||
                  'https://live-transfer-urls.s3.amazonaws.com/defaults/voicemail.mp3',
                liveTransferVoip: vendorLayer?.liveTransferVoip || '',
                liveTransferWaitUrl:
                  vendorLayer?.liveTransferWaitUrl ||
                  'https://live-transfer-urls.s3.amazonaws.com/defaults/phone_dial.mp3',
                liveTransfersEnabled: !!vendorLayer.liveTransfersEnabled,
                liveTransferScript: vendorLayer?.liveTransferScript || '',
                liveTransferEarlyHangUpLayerID:
                  vendorLayer.liveTransferEarlyHangUpLayerID,
                liveTransferIntroGreeting:
                  vendorLayer?.liveTransferIntroGreeting || true,
                apBidEnhancmentEnabled:
                  vendorLayer?.apBidEnhancmentEnabled || false,
                apSettings: vendorLayer?.apSettings || {
                  apBidEnhancmentDynamic: true,
                  apPercentageEnabled: true,
                  apWPMMin: 1,
                  apWPMMax: 200,
                  apKPMMin: 1,
                  apKPMMax: 500,
                  apBidPrice: 0,
                  apBidPercentage: 0,
                },
              }
        }
        // finals checks.
        onSubmit={(values) => {
          console.log(values);
          let vendorCampaigns = [];
          console.log(values.filters);
          const customPricing = (vC: any): object => ({
            customPricingEnabled: vC.customPricingEnabled || false,
            dynamicPricingEnabled: !!vC.dynamicPricingEnabled,
            defaultPercentageEnabled: vC.defaultPercentageEnabled || false,
            defaultPercentage: vC.defaultPercentage || 0,
            defaultPrice: vC.defaultPrice || 0,
            apBidEnhancementCategoryOveride: !!vC?.apBidEnhancementCategoryOveride,
            overrideMLPrice: !!vC?.overrideMLPrice,
            overrideMaxMLPrice: !!vC?.overrideMaxMLPrice,
          });

          const encodeString = (str: string): string =>
            encodeURI(str.trim())
              .replaceAll('_', '%5F')
              .replaceAll('.', '%2e')
              .replaceAll("'", '%27');

          const parsedFields = (vC: any): string[] =>
            vC.categoryFields
              .map((field: string): string => encodeString(field))
              .slice(0, values.vendorSourceCampaignMapArray.length)
              .join(' ');

          if (isNew) {
            vendorCampaigns = values?.vendorCampaigns
              .map((vC) => {
                return {
                  mpVendorCampaignID: '',
                  campaignName: values.categoryMappingEnabled
                    ? parsedFields(vC)
                    : vC.campaignName,
                  description: vC.description,
                };
              })
              .concat({
                campaignName: '',
                mpVendorCampaignID: '',
                default: true,
              });
          } else {
            const newDescription =
              values.description !== vendorLayer?.description;
            vendorCampaigns = values?.vendorCampaigns
              .map((vC: any) => ({
                mpVendorCampaignID: vendorLayerCampaigns.some(
                  (oldVc) => oldVc?.campaignName === parsedFields(vC)
                )
                  ? vC?.mpVendorCampaignID
                  : '',
                campaignName: values.categoryMappingEnabled
                  ? parsedFields(vC)
                  : vC.campaignName,
                ...customPricing(vC),
                description: vC.description,
              }))
              .concat(
                newDescription
                  ? {
                      campaignName: '',
                      mpVendorCampaignID:
                        values.defaultVendorCampaign.mpVendorCampaignID || '',
                      default: true,
                    }
                  : []
              );
          }
          start();
          createOrUpdate({
            variables: {
              input: {
                // Base
                id: vendorLayer?.id, // todo - get from nodeId
                networkID: network!.networkID,
                vendorID: values.vendor!.vendorID,
                description: encodeString(values.description),
                tags: values.tags,
                verticalType: values.verticalType,
                kindOfLead: values.kindOfLead,
                brokerID: values.brokerID,
                acceptsTermsAndConditions: values.acceptsTermsAndConditions,
                maxDemandUnsoldAccepted: values.maxDemandUnsoldAccepted,
                splitCount: values.splitCount,
                isUpdateEligible: values.isUpdateEligible,
                delaySecondsForUpdate: values.delaySecondsForUpdate,
                mlTargetConversionRate: values.mlTargetConversionRate,
                mlProbablityIncreasePercentage:
                  values.mlProbablityIncreasePercentage,
                mlProbablityIncreasePerLead: values.mlProbablityIncreasePerLead,
                mlBeforeThreashold: values.mlBeforeThreashold,
                allowMLPriceOverride: values.allowMLPriceOverride,
                mlTargetMinPrice: values.mlTargetMinPrice,
                mlTargetMaxPrice: values.mlTargetMaxPrice,
                minAcceptenceBid: values.minAcceptenceBid,
                mlAggressionConversionLevel: values.mlAggressionConversionLevel,
                mlAggressionPriceLevel: values.mlAggressionPriceLevel,
                useEventsMLModel: values.useEventsMLModel,
                vendorPrice:
                  values.apiType === 'pingPost'
                    ? 0
                    : parseFloat(values.vendorPrice), // todo - make sure in decimal?
                // Ping Post
                fixedBidPrice:
                  values.apiType === 'pingPost' ? values.fixedBidPrice : 0,
                // Block/enhance
                isCampaignDestination: values.isCampaignDestination,
                skipActiveDemandSubmit: values.skipActiveDemandSubmit,
                overideDuplicateChecks: values.overideDuplicateChecks,
                allowMLOverride: values.allowMLOverride,
                allowMLearning: values.allowMLearning,
                smartPriceAcceptence: values.smartPriceAcceptence,
                dupPriceThreashold: values.dupPriceThreashold,
                dupPriceThreasholdLookBack: values.dupPriceThreasholdLookBack,
                allowAPOnPing: values.allowAPOnPing,
                overrideBlockedAttributeFields:
                  values.overrideBlockedAttributeFields,
                overrideBlockedValues: values.overrideBlockedValues,
                crossNetworkLayers: values.crossNetworkLayers,
                allowSlices: values.allowSlices,
                allowSplits: values.allowSplits,
                rejectTresstle: values.rejectTresstle,
                tresstleScoreMin: values.tresstleScoreMin,
                apiType: values.apiType,
                maxSpendPerHour: values.maxSpendPerHour,
                maxSpend: values.maxSpend,
                pingPostEnabled: values.pingPostEnabled,
                pingPostType: values.pingPostType,
                skipGTBDuplicate: values.skipGTBDuplicate,
                rejectDisconnected: values.rejectDisconnected,
                rejectDNC: values.rejectDNC,
                rejectDuplicates: values.rejectDuplicates,
                enhanceProfile: values.enhanceProfile,
                refundEligible: values.refundEligible,
                refundMaxAge: values.refundMaxAge,
                refundMaxAgeDateType: values.refundMaxAgeDateType,
                refundMaxAgeIncrement: values.refundMaxAgeIncrement,
                verifyDestination: values.verifyDestination,
                // Filters
                filters: values.filters.map((f: any) => {
                  if (f.rejectMessage === null) {
                    delete f.rejectMessage;
                  }
                  const values = f?.values?.map((v: any) => v.label);
                  return omit({ ...f, values }, '__typename');
                }),
                postAttributes: values.postAttributes,
                pingAttributes: values.pingAttributes,
                attributeRanges: values.attributeRanges,
                // Categories
                vendorSourceCampaignMap: values.categoryMappingEnabled
                  ? values.vendorSourceCampaignMapArray?.join(':')
                  : '', // TODO,
                categories: vendorCampaigns,
                // Auto Process
                autoProcess: values.autoProcess,
                autoProcessThreshold: values.autoProcessThreshold,
                // Live Transfers
                liveTransferAnswerTime: values.liveTransferAnswerTime,
                liveTransferBatchCycleCount: values.liveTransferBatchCycleCount,
                liveTransferBatchSize: values.liveTransferBatchSize,
                liveTransferDelaySecondsForCall: values.liveTransferDoNotOverflow
                  ? 300
                  : convertDelaySecondsForCallToSeconds(
                      values.liveTransferTimeConversion,
                      values.liveTransferDelaySecondsForCall
                    ),
                liveTransferDoNotOverflow: values.liveTransferDoNotOverflow,
                liveTransferDropCall: values.liveTransferDropCall,
                liveTransferEmail: values.liveTransferEmail,
                liveTransferOverflowRedirectCall:
                  values.liveTransferOverflowRedirectCall,
                liveTransferNotificationsOnly:
                  values.liveTransferNotificationsOnly,
                liveTransferPhoneNumber: values?.liveTransferPhoneNumber,
                liveTransferRejectNoDataCalls:
                  values?.liveTransferRejectNoDataCalls,
                liveTransferTierID: values?.liveTransferTierID,
                liveTransferVoiceMailUrl: values?.liveTransferVoiceMailUrl,
                liveTransferVoip: values.liveTransferVoip,
                liveTransferWaitUrl: values?.liveTransferWaitUrl,
                liveTransfersEnabled: values.liveTransfersEnabled,
                liveTransferScript: values?.liveTransferScript?.trim(),
                liveTransferEarlyHangUpLayerID:
                  values.liveTransferEarlyHangUpLayerID,
                liveTransferIntroGreeting: values?.liveTransferIntroGreeting,
                apBidEnhancmentEnabled: values?.apBidEnhancmentEnabled || false,
                apSettings: values.apSettings || {
                  apBidEnhancmentDynamic: true,
                  apPercentageEnabled: true,
                  apWPMMin: 1,
                  apWPMMax: 200,
                  apKPMMin: 1,
                  apKPMMax: 500,
                  apBidPrice: 0,
                  apBidPercentage: 0,
                },
              },
            },
          });
        }}
        validationSchema={yup.object().shape({
          // Base
          vendor: yup.mixed().required('This field is required.'),
          description: yup.string().required('This field is required.'),
          brokerID: yup.string().required('This field is required.'),
          // .matches(
          //   new RegExp(/^[a-zA-Z0-9\s]*$/),
          //   'Description may only contain letters, numbers, and spaces.'
          // )
          vendorPrice: yup
            .number()
            .min(0, 'The default lead price must be positive.')
            .test(
              'has-dynamic-tier-fixedprice',
              'Lead Source is assigned to a dynamic price tier and must have a non Zero default price.',
              (val: any, ctx: yup.TestContext<Record<any, any>>) => {
                if (ctx.parent.apiType === 'pingPost') {
                  return true;
                }
                if (!tiers?.some((t) => t.dynamicPrice)) {
                  return true;
                }
                return val > 0;
              }
            ),
          // Ping Post
          fixedBidPrice: yup
            .number()
            .min(0, 'The Ping Post price must be positive.')
            .test(
              'has-dynamic-tier-pingpost',
              'Lead Source is assigned to a dynamic price tier and must have a non Zero default price.',
              (val: any, ctx: yup.TestContext<Record<any, any>>) => {
                if (!(ctx.parent.apiType === 'pingPost')) {
                  return true;
                }
                if (!tiers?.some((t) => t.dynamicPrice)) {
                  return true;
                }
                return val > 0;
              }
            ),
          maxDemandUnsoldAccepted: yup.number(),
          splitCount: yup.number(),
          isUpdateEligible: yup.boolean(),
          delaySecondsForUpdate: yup.number(),
          mlTargetConversionRate: yup.number(),
          mlProbablityIncreasePerLead: yup.number(),
          mlBeforeThreashold: yup.boolean(),
          mlProbablityIncreasePercentage: yup.number(),
          allowMLPriceOverride: yup.boolean(),
          mlTargetMinPrice: yup.number(),
          mlTargetMaxPrice: yup.number(),
          mlAggressionConversionLevel: yup.number(),
          mlAggressionPriceLevel: yup.number(),
          apiType: yup.string(),
          pingPostEnabled: yup.boolean(),
          pingPostType: yup.string(),
          skipGTBDuplicate: yup.boolean(),
          apBidEnhancmentEnabled: yup.boolean(),
          apSettings: yup.object(),
          filters: yup.array().of(
            yup.object().shape({
              type: yup.string(),
              attribute: yup.string(),
              functionName: yup.string(),
              level: yup
                .string()
                .when(['type'], {
                  is: (t) => t === 'STATIC',
                  then: (schema: yup.StringSchema) => {
                    return schema.required('This field is required.');
                  },
                })
                .nullable(),
              include: yup.bool().when(['type'], {
                is: (t) => t !== 'DYNAMIC',
                then: (schema: yup.BooleanSchema) => {
                  return schema.required('This field is required.');
                },
              }),
              min: yup.number().when(['type'], {
                is: (t) => t === 'TIME' || t === 'RANGE',
                then: (schema: yup.NumberSchema) => {
                  return schema
                    .required('This field is required.')
                    .min(0, 'Must be greater than 0')
                    .test(
                      'is-decimal',
                      'Value cannot be a decimal',
                      (value) => !`${value}`.match(/^\d*\.{1}\d*$/)
                    );
                },
              }),
              max: yup.number().when(['type'], {
                is: (t) => t === 'TIME' || t === 'RANGE',
                then: (schema: yup.NumberSchema) => {
                  return schema
                    .required('This field is required.')
                    .min(0, 'Must be greater than 0')
                    .test(
                      'is-decimal',
                      'Value cannot be a decimal',
                      (value) => !`${value}`.match(/^\d*\.{1}\d*$/)
                    );
                },
              }),
              ruleID: yup.string().when(['type'], {
                is: 'MULTI',
                then: (schema: yup.NumberSchema) => {
                  return schema.required('This field is required.');
                },
              }),
              rejectIndex: yup
                .array()
                .of(yup.number())
                .when(
                  ['MULTI'],
                  (level: string, schema: yup.ArraySchema<string>) => {
                    if (level === 'layerID') {
                      return schema.min(1, 'Must have at least 1 value.');
                    }
                    return schema;
                  }
                ),
              minimumRefresh: yup.number().when(['type'], {
                is: (t) => t === 'DYNAMIC',
                then: (schema: yup.NumberSchema) => {
                  return schema
                    .required('This field is required.')
                    .min(0, 'Must be greater than 0')
                    .test(
                      'is-decimal',
                      'Value cannot be a decimal',
                      (value) => !`${value}`.match(/^\d*\.{1}\d*$/)
                    );
                },
              }),
              values: yup.array().of(
                yup.object().shape({
                  label: yup.string(),
                  value: yup
                    .string()
                    .when(
                      ['level', 'type'],
                      (
                        level: string,
                        type: string,
                        schema: yup.ArraySchema<string>
                      ) => {
                        if (level === 'layerID' || type === 'MULTI') {
                          return schema.min(1, 'Must have at least 1 value.');
                        }
                        return schema;
                      }
                    ),
                })
              ),
            })
          ),
          vendorSourceCampaignMapArray: yup.array().of(
            yup.string().when('categoryMappingEnabled', {
              is: true,
              then: (schema) => schema.isRequired(true, 'Required Field.'),
            })
          ),
          vendorCampaigns: yup.array().when('categoryMappingEnabled', {
            is: true,
            then: (schema) =>
              schema.of(
                yup
                  .object()
                  .shape({
                    campaignName: yup
                      .string()
                      .test(
                        'len',
                        'Vendor campaign name needs to be less than 200 characters',
                        (val) => (val?.length || 0) < 200
                      ),
                    categoryFields: yup
                      .array()
                      .of(yup.string().isRequired(true, 'Required Field')),
                    description: yup
                      .string()
                      .isRequired(true, 'Required Field'),
                    defaultPercentage: yup
                      .number()
                      .when('defaultPercentageEnabled', {
                        is: true,
                        then: (schema) =>
                          schema.min(1, 'Must be greater than 0%'),
                      })

                      .test(
                        'is-decimal',
                        'Value cannot be a decimal',
                        (value) => !`${value}`.match(/^\d*\.{1}\d*$/)
                      ),
                    defaultPrice: yup.number().when('customPricingEnabled', {
                      is: true,
                      then: () =>
                        yup
                          .number()
                          .isRequired(true, 'Required Field')
                          .test(
                            'is not greater than default price',
                            'Cannot have negative Price',
                            (val: any, ctx) => {
                              const priceType =
                                ctx.from?.[1].value?.apiType === 'direct'
                                  ? 'vendorPrice'
                                  : 'fixedBidPrice';
                              const defaultLayerPrice =
                                ctx.from?.[1].value?.[priceType] *
                                (priceType === 'vendorPrice' ? 100 : 1);

                              return val + defaultLayerPrice > 0;
                            }
                          )
                          .when('dynamicPricingEnabled', {
                            is: false,
                            then: () => yup.number().min(0, 'Negative Field'),
                          })
                          .when('dynamicPricingEnabled', {
                            is: true,
                            then: () => yup.number(),
                          }),
                    }),
                  })
                  .test(
                    'duplicate-field',
                    'Duplicate category identifiers.',
                    (
                      val: any,
                      ctx: yup.TestContext<Record<string, any>>
                    ): boolean => {
                      return !ctx.parent.find((category: any) => {
                        return !!(
                          val.mpVendorCampaignID !==
                            category.mpVendorCampaignID &&
                          category.categoryFields?.join(':') ===
                            val.categoryFields?.join(':')
                        );
                      });
                    }
                  )
              ),
          }),
          autoProcessThreshold: yup.number().moreThan(999),
          autoProcess: yup.boolean(),
          liveTransferBatchCycleCount: yup
            .number()
            .nullable()
            .when(['liveTransfersEnabled'], {
              is: true,
              then: (schema: yup.NumberSchema) =>
                schema
                  .min(1, 'Cycles must be greater than zero.')
                  .required('Cycles are required (Default: 2).'),
            }),
          liveTransferBatchSize: yup
            .number()
            .nullable()
            .when(['liveTransfersEnabled'], {
              is: true,
              then: (schema: yup.NumberSchema) =>
                schema
                  .min(1, 'Batch Size must be greater than zero.')
                  .required(
                    'Please specify how many agents to notify (Default: 15).'
                  ),
            }),
          liveTransferAnswerTime: yup
            .number()
            .nullable()
            .when(['liveTransfersEnabled'], {
              is: true,
              then: (schema: yup.StringSchema) =>
                schema
                  .min(1, 'Answer Time  must be greater than zero.')
                  .required(
                    'Please specify the second interval that we notify the agents in (Default: 10).'
                  ),
            }),
          liveTransferEmail: yup
            .string()
            .when(
              ['liveTransferOverflowRedirectCall', 'liveTransfersEnabled'],
              {
                is: (liveTransferOverflowRedirectCall, liveTransfersEnabled) =>
                  liveTransfersEnabled === true &&
                  liveTransferOverflowRedirectCall === false,
                then: yup.string().when(['liveTransferDropCall'], {
                  is: false,
                  then: (schema: yup.StringSchema) =>
                    schema
                      .email('Must be a valid email.')
                      .required('Please enter a valid email.'),
                }),
              }
            ),
          liveTransferTierID: yup.string().when(['liveTransfersEnabled'], {
            is: true,
            then: (schema: yup.StringSchema) =>
              schema.required('Price Tier is required'),
          }),
          liveTransferVoiceMailUrl: yup
            .string()
            .when(['liveTransfersEnabled'], {
              is: true,
              then: (schema: yup.StringSchema) =>
                schema.required('This field is required.'),
            }),
          liveTransferWaitUrl: yup.string().when(['liveTransfersEnabled'], {
            is: true,
            then: (schema: yup.StringSchema) =>
              schema.required('This field is required.'),
          }),
          liveTransferVoip: yup
            .string()
            .when(
              ['liveTransferOverflowRedirectCall', 'liveTransfersEnabled'],
              {
                is: (liveTransferOverflowRedirectCall, liveTransfersEnabled) =>
                  liveTransfersEnabled === true &&
                  liveTransferOverflowRedirectCall === true,
                then: yup.string().when(['liveTransferDropCall'], {
                  is: false,
                  then: (schema: yup.StringSchema) =>
                    schema
                      .matches(
                        /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
                        'Phone number is not valid'
                      )
                      .required('Call forwarding requires a number'),
                }),
              }
            ),
          liveTransferDelaySecondsForCall: yup
            .number()
            .when(['liveTransferDoNotOverflow', 'liveTransfersEnabled'], {
              is: (liveTransferDoNotOverflow, liveTransfersEnabled) =>
                liveTransfersEnabled === true &&
                liveTransferDoNotOverflow === false,
              then: (schema: yup.StringSchema) =>
                schema
                  .min(1, 'Wait time must be greater than zero.')
                  .required(
                    'Please specify how much time to wait for a call to  come in (Default: 5 minutes).'
                  ),
            }),
          acceptsTermsAndConditions: yup.boolean().when('filters', {
            is: (filters) => filters.length > 0 && Vars.env !== 'dev',
            then: yup.bool().oneOf([true], 'Field must be checked'),
          }),
          attributeRanges: yup.object().shape({
            age: intRangesYup,
            household: intRangesYup,
            income: intRangesYup,
          }),
        })}
      >
        {({ values, setFieldValue, errors }) => {
          useEffect(() => {
            if (errors) {
              console.log('Validation Errors:', errors);
            }
          }, [errors]);
          useEffect(() => {
            setFieldValue('vendorCampaigns', handledVendorCampaigns || []);
            setFieldValue(
              'defaultVendorCampaign',
              vendorLayerCampaigns.filter((vC: any) => vC?.default)[0]
            );
          }, [vendorLayerCampaigns]);
          return (
            <Row className="mt-3">
              <SubmitErrors />
              <Col xs={12}>
                <Card className="mb-2">
                  <CardHeader className="d-flex align-items-center justify-content-between">
                    <div className="d-flex flex-fill flex-row">
                      <BackButton className="mr-2" />
                      <CardTitle tag="h5" className="h4 mb-0 py-2">
                        {vendorLayer?.id
                          ? vendorLayer.description ||
                            vendorLayer.vendorSourceCampaign
                          : 'New Lead Source'}
                      </CardTitle>
                    </div>
                    <div className="text-muted">{vendorLayer?.layerID}</div>
                  </CardHeader>
                </Card>
              </Col>
              <Col xs={12}>
                <div className="d-flex flex-row">
                  <Nav tabs className="flex-fill mr-2">
                    <TabHeader
                      name="connection"
                      fields={[
                        'vendor',
                        'description',
                        'fixedBidPrice',
                        'vendorPrice',
                        'liveTransfersEnabled',
                        'apiType',
                        'hasBudget',
                        'pingPostEnabled',
                        'pingPostType',
                        'refundEligible',
                        'enhanceProfile',
                        'rejectDisconnected',
                        'rejectDNC',
                        'rejectDuplicates',
                        'acceptsTermsAndConditions',
                      ]}
                      activeTab={activeTab}
                      onClick={setActiveTab}
                    >
                      Connection & Quality
                    </TabHeader>
                    <TabHeader
                      name="filters"
                      fields={['filters', 'skipGTBDuplicate']}
                      activeTab={activeTab}
                      onClick={setActiveTab}
                    >
                      Data Filters
                    </TabHeader>

                    {network?.flags.allowAttributes ||
                    role === 'internal_admin' ||
                    role === 'super_internal_admin' ? (
                      <TabHeader
                        name="attributes"
                        fields={[]}
                        activeTab={activeTab}
                        onClick={setActiveTab}
                      >
                        Attributes
                      </TabHeader>
                    ) : null}
                    <TabHeader
                      name="addons"
                      fields={['addons']}
                      activeTab={activeTab}
                      onClick={setActiveTab}
                    >
                      Addons
                    </TabHeader>
                    {values.liveTransfersEnabled && (
                      <TabHeader
                        name="transfers"
                        fields={[
                          'liveTransferAnswerTime',
                          'liveTransferBatchCycleCount',
                          'liveTransferBatchSize',
                          'liveTransferDelaySecondsForCall',
                          'liveTransferDoNotOverflow',
                          'liveTransferEmail',
                          'liveTransferOverflowRedirectCall',
                          'liveTransferPhoneNumber',
                          'liveTransferRejectNoDataCalls',
                          'liveTransferTierID',
                          'liveTransferVoiceMailUrl',
                          'liveTransferVoip',
                          'liveTransferWaitUrl',
                        ]}
                        activeTab={activeTab}
                        onClick={setActiveTab}
                      >
                        Live Transfers
                      </TabHeader>
                    )}
                    {(network?.allowCategories ||
                      ['internal_admin', 'super_internal_admin'].includes(
                        role
                      )) && (
                      <TabHeader
                        name="categories"
                        fields={[
                          'categoryMappingEnabled',
                          'vendorSourceCampaignMapArray',
                          'vendorCampaigns',
                        ]}
                        activeTab={activeTab}
                        onClick={setActiveTab}
                      >
                        Categories
                      </TabHeader>
                    )}
                    <TabHeader
                      name="uploads"
                      fields={['autoProcess', 'autoProcessThreshold']}
                      activeTab={activeTab}
                      onClick={setActiveTab}
                    >
                      Upload Settings
                    </TabHeader>
                    <TabHeader
                      name="other"
                      fields={[
                        'isCampaignDestination',
                        'skipActiveDemandSubmit',
                        'overideDuplicateChecks',
                        'allowMLOverride',
                        'allowMLearning',
                        'smartPriceAcceptence',
                        'dupPriceThreashold',
                        'dupPriceThreasholdLookBack',
                        'verifyDestination',
                        'allowAPOnPing',
                        'allowSlices',
                        'allowSplits',
                        'useEventsMLModel',
                        'allowMLPriceOverride',
                        'minAcceptenceBid',
                        'mlTargetMaxPrice',
                        'mlTargetMinPrice',
                        'mlTargetConversionRate',
                        'mlProbablityIncreasePercentage',
                        'mlProbablityIncreasePerLead',
                        'mlBeforeThreashold',
                        'rejectTresstle',
                        'tresstleScoreMin',
                      ]}
                      activeTab={activeTab}
                      onClick={setActiveTab}
                    >
                      Other
                    </TabHeader>
                  </Nav>
                  <Button
                    id="create-update-lead-source-button"
                    color="primary"
                    className="float-right"
                    type="submit"
                  >
                    {isNew ? 'Create' : 'Update'}
                  </Button>
                </div>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="connection">
                    <GeneralTab vendorLayer={vendorLayer} />
                  </TabPane>
                  <TabPane tabId="categories">
                    <CategoriesTab isNew={isNew} />
                  </TabPane>
                  <TabPane tabId="attributes">
                    <AttributesTab />
                  </TabPane>
                  <TabPane tabId="uploads">
                    <UploadTab />
                  </TabPane>
                  <TabPane tabId="filters">
                    <FiltersTab network={network} />
                  </TabPane>
                  <TabPane tabId="addons">
                    <AddonsTab />
                  </TabPane>
                  <TabPane tabId="transfers">
                    <LiveTransfersTab />
                  </TabPane>
                  <TabPane tabId="other">
                    <OtherTab />
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          );
        }}
      </Form>
    </Container>
  );
};

export default DetailView;
