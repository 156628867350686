import React, { createContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { currentAdminQuery } from '@/graphql/queries';

export interface NotificationContextType {
  name: string;
  email: string;
  role: 'admin' | 'internal_admin' | 'super_internal_admin' | 'support';
  agentID: string;
  otherBrokerCredits: any[];
}
export const CurrentUserContext = createContext<NotificationContextType>({
  name: '',
  email: '',
  role: 'admin',
  agentID: '',
  otherBrokerCredits: [],
});

const CurrentUserProvider: React.FC = ({ children }) => {
  const { data, loading } = useQuery(currentAdminQuery);

  return (
    <CurrentUserContext.Provider value={data?.currentAdmin || {}}>
      {loading ? null : children}
    </CurrentUserContext.Provider>
  );
};

export default CurrentUserProvider;
