import gql from 'graphql-tag';
import { PriceTierBase } from './PriceTierFragment';
import { NetworkBase } from './NetworkFragment';
import { vendorPartial } from './VendorFragment';

const AgentFragment = gql`
  fragment AgentFragment on Agent {
    id
    __typename
    networkID
    networkIDs
    agentID
    profileID
    mainGroupID
    organizationID
    legacySync
    agentCorpID
    brokers
    managers {
      __typename
      id
      agentID
      firstName
      lastName
    }
    agentIDs
    agentApproved
    subID
    totalCredit
    autoFundSettings {
      threshold
      amount
      enabled
    }
    lastModified
    status
    callerReadyStatus
    callerReadyAccountStatus
    lastCallerReadyCharge
    lastCallerReadyChargeResult
    lastCallerReadyLogin
    agentCallerReadyID
    agentCallerReadyGRID
    hasCallQualifier
    callQualifierID
    lastModifiedBy {
      __typename
      id
      networkID
      agentID
      firstName
      lastName
    }
    network {
      ${NetworkBase}
    }
    organization {
      ${NetworkBase}
    }
    allowedLeadTiers {
      ${PriceTierBase}
    }
    allowedVendors{
      ${vendorPartial}
    }
    flags {
      allowManagerDashboard
      allowCallerReady
      allowSideline
      allowCrossNetworkTransfers
      allowMarketplaceBrokering
      allowMyAgents
      allowBeta
      allowVendor
      allowVendors
      allowSelfEdit
      canDeliverDuplicateLeads
      allowBrokerRejects
      allowCustomAPIs
      allowBulkSales
      allowRegisterForOutboundTransfers
    }
    agentCRMID
    marketplace
    companyID
    email
    firstName
    lastName
    phone
    buyerID
    companyID
    userID
    phone
    team
    role
    crmProfiles {
      vanillaSoftDirect {
        label
        crmTeamName
        crmUserID
        crmCampaign
        crmUrl
        crmUrlID
        profiles {
          id
          profileID
          description
          crmFields
          crmExcludeUserID
        }
      }
      iSalesCRM {
        crmToken
        crmUserID
        crmTeamName
        sid
        label
        iSalesUserName
        profiles {
          description
          id
          profileID
          crmFields
        }
      }
      convoso {
        label
        convosoAgentEmail
        convosoAgentID
        profiles {
          description
          id
          profileID
          crmFields
        }
      }
    }
      otherBrokerCredits {
        brokerName
        brokerID
        credits
    }
  }
`;

export default AgentFragment;
