import gql from 'graphql-tag';

export const PriceTierBase = `
    id
    __typename
    price
    name
    description
    priceTierID
    networkID
    network {
      __typename
      id
    }
    instanceIDs
    fallbackIncrement
    fallbackAmount
    dynamicPrice
    minPrice
    maxPrice
    agentResponsiblePercentage
    allowedFilters
    fallbackPriceTierID
    status
    marketplace
    vertical
    liveTransfers
    liveTransferPrice
    isAged
    isOverflow
    chargeDelayThreshold
    agentCreditOnly
    skipDupCheck
    addDupBackToQueue
    allowedDestinationTypes
    bulkPurchase
    bulkPurchaseMax
    bulkPurchaseTimeout
    bulkPurchaseCustom
    tierType
    requiredDestination
    maxLeadCountPerDay
    maxDuplicates
    analyticStreamOn
    tierType
    maxLeadCountPerDay
    maxCampaignCount
    tierType
    bulkPurchaseOnly
    handlerV2
    concurrencyLimit
    spendingPeriodSubsetCount
    isOptic
    totalNoMatch
    queryLimit
    vcConcurrency
`;

const PriceTierFragment = gql`
  fragment PriceTierFragment on PriceTier {
    ${PriceTierBase}
    assignedAgents
    activeSpendingPeriods
    totalCampaigns
    assignedLiveTransferLayers {
      description
      liveTransferPhoneNumber
      vendorID
      layerID
    }
    mpVendorCampaignIDs
    overflowMpVendorCampaignIDs
    redisPriceTier{
      status
      serverStatus
      cycleDuration
      lastCompletionEpoch
    }
    priceBreakPoints {
      breakPoint
      breakPointPrice
    }
    bulkPurchaseMin
  }`;

export const PriceTierBaseFragment = gql`
  fragment PriceTierFragment on PriceTier {
    ${PriceTierBase}
  }`;

export default PriceTierFragment;
