import React, { useContext, useEffect, useState, useMemo } from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import moment from 'moment';
import { useQuery } from '@apollo/react-hooks';
import { CurrentUserContext } from '@/context';
import { Form, Field } from '@availity/form';
import { formatCurrencyForDisplay } from '@/utils';
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Row,
  Col,
  Button,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Label,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
  Alert,
} from 'reactstrap';
import * as yup from 'yup';
import {
  FormikSwitch,
  ManagerSelect,
  PriceTierSelect,
  MoneyInput,
  VendorSelect,
  ProfileAddCreditModal,
} from '@/components';
import { useAdmin, useNetwork, useNetworks } from '@/hooks';
import { SelectField } from '@availity/select';
import useAgent from './useAgent';

const roleLabels = {
  super_admin: 'Super Admin',
  admin: 'Admin',
  user: 'User',
  manager: 'Manager',
};

const getStatus = (agentApproved: boolean, status?: 'active' | 'deleted') => {
  if (status === 'deleted') {
    return {
      color: 'danger',
      label: 'Disabled',
    };
  }

  return agentApproved
    ? {
        color: 'primary',
        label: 'Active',
      }
    : {
        color: 'warning',
        label: 'Pending',
      };
};

const General: React.FC<RouteComponentProps<{
  id: string;
}>> = () => {
  const { network } = useNetwork();
  const { user, loading, update } = useAgent();
  const {
    role,
    agentID: adminAgentID,
    otherBrokerCredits: adminOtherBrokerCredits,
    name,
  } = useAdmin();

  const { data: networkData } = useNetworks({
    fetchPolicy: 'cache-only',
  });

  // console.log(agentID)
  // console.log(adminOtherBrokerCredits)

  const [cardModal, setCardModal] = useState(false);
  const [creditModal, setCreditModal] = useState(false);
  const [selectedBroker, setSelectedBroker] = useState({
    brokerID: '',
    brokerName: '',
    agentID: '',
    networkID: '',
    availibleCredit: 0,
    fromAgentID: '',
  });
  const toggleCreditModal = ({ broker = {}, refresh }: any): void => {
    const {
      brokerID,
      brokerName,
      agentID,
      networkID,
      availibleCredit,
      fromAgentID,
    } = broker;
    setSelectedBroker({
      brokerID,
      brokerName,
      agentID,
      networkID,
      availibleCredit,
      fromAgentID,
    });
    setCreditModal(!creditModal);
    if (refresh) {
      window.location.reload();
    }
  };

  // eslint-disable-next-line consistent-return
  const mainGroups = useMemo(() => {
    if (network?.groups) {
      return network?.groups.map((group: { groupID: any; name: any }) => {
        const { groupID, name } = group;
        return { label: name, value: groupID };
      });
    }
    return [];
  }, [network]);

  if (loading || !user) {
    return null;
  }
  const isAllowedToAssignVendors =
    role === 'internal_admin' || role === 'super_internal_admin';

  const status = getStatus(user.agentApproved!, user.status?.toLowerCase());

  return (
    <>
      <Form
        initialValues={{
          companyID: user.companyID,
          networkID: user.networkID,
          networkIDs: user.networkIDs || [],
          marketplace: user.marketplace,
          agentApproved: user.agentApproved,
          role: user.role,
          organizationID: user.organizationID,
          profileID: user.profileID,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          phone: user.phone,
          flags: user.flags,
          managers: user.managers || [],
          allowedLeadTiers: user.allowedLeadTiers || [],
          allowedVendors: user.allowedVendors || [],
          agentCorpID: user.agentCorpID || '',
          mainGroupID: user.mainGroupID,
          brokers: user.otherBrokerCredits.map(({ brokerID }) => {
            return brokerID;
          }),
        }}
        validationSchema={yup.object().shape({
          firstName: yup.string().required('This field is required.'),
          lastName: yup.string().required('This field is required.'),
          email: yup
            .string()
            .email('Must be a valid email')
            .required('This field is required.'),
          phone: yup
            .string()
            .phone('Must be a valid phone number.')
            .required('This field is required.'),
          flags: yup.object().shape({
            allowManagerDashboard: yup.bool(),
            allowCallerReady: yup.bool(),
            allowMarketplaceBrokering: yup.bool(),
            allowBrokerRejects: yup.bool(),
            allowMyAgents: yup.bool(),
            allowBeta: yup.bool(),
            allowVendor: yup.bool(),
            allowVendors: yup.bool(),
            allowSelfEdit: yup.bool(),
            canDeliverDuplicateLeads: yup.bool(),
            allowCustomAPIs: yup.bool(),
            allowBulkSales: yup.bool(),
          }),
          managers: yup.array().nullable().default([]),
        })}
        onSubmit={(
          { managers, allowedLeadTiers, allowedVendors, ...values },
          { resetForm }
        ) => {
          update({
            variables: {
              input: {
                ...values,
                networkIDs: values.networkIDs,
                email: values.email?.toLowerCase(),
                managerIDs: managers?.map((m) => m.agentID!) || [],
                allowedVendors: allowedVendors?.map((v) => v.vendorID) || [],
                allowedLeadTiers:
                  allowedLeadTiers?.map((t) => t.priceTierID) || [],
                agentID: user.agentID,
                subID: user.subID,
                id: user.id,
              },
            },
            onCompleted: () => resetForm(),
          });
        }}
      >
        {({ values, initialValues, setFieldValue, resetForm }) => {
          useEffect(() => {
            resetForm();
          }, [user?.agentID]);

          useEffect(() => {
            if (
              initialValues.networkIDs &&
              initialValues.networkIDs.length > 0 &&
              values.networkIDs === null
            ) {
              setFieldValue('networkIDs', []);
            }
          }, [values.networkIDs]);

          useEffect(() => {
            if (
              network &&
              initialValues.networkIDs?.some(
                (nId) => !values.networkIDs?.includes(nId)
              )
            ) {
              setFieldValue(
                'allowedLeadTiers',
                values.allowedLeadTiers.filter(
                  (t) =>
                    t.networkID === network?.networkID ||
                    values.networkIDs?.includes(t.networkID)
                )
              );
            }
          }, [values.networkIDs]);

          return (
            <>
              {values.agentApproved === false && user.agentApproved === true && (
                <Alert color="warning">
                  <span className="font-weight-bold">WARNING: </span>Unapproving
                  agent will auto pause all of his/her active campaigns.
                </Alert>
              )}
              <Card className="mb-3">
                <CardHeader className="d-flex justify-content-between">
                  <CardTitle
                    tag="h5"
                    className="py-2 h4 mb-0 text-uppercase d-flex align-items-center"
                  >
                    Profile{' '}
                    <Badge color={status.color} className="ml-3">
                      {status.label}
                    </Badge>
                  </CardTitle>
                  {user?.lastModified && (
                    <div>
                      <div>
                        Last Modified{' '}
                        {moment(user?.lastModified).format('MM/DD/YY hh:mm A')}
                      </div>
                      <div>
                        By{' '}
                        {user?.networkID !== user.lastModifiedBy.networkID ? (
                          <>
                            {user?.lastModifiedBy.firstName}{' '}
                            {user?.lastModifiedBy.lastName}
                          </>
                        ) : (
                          <Link
                            to={`/agents/${user?.lastModifiedBy.id}/general`}
                          >
                            {user?.lastModifiedBy.firstName}{' '}
                            {user?.lastModifiedBy.lastName}
                          </Link>
                        )}
                      </div>
                    </div>
                  )}
                </CardHeader>
                <CardBody tag={Row} noGutters>
                  <Col xs={3}>
                    <Field
                      name="firstName"
                      label="First Name"
                      placeholder="Agent First Name..."
                    />
                  </Col>
                  <Col
                    xs={{
                      size: 3,
                      offset: 1,
                    }}
                  >
                    <Field
                      name="lastName"
                      label="Last Name"
                      placeholder="Agent Last Name..."
                    />
                  </Col>
                  <Col
                    xs={{
                      size: 3,
                      offset: 1,
                    }}
                  >
                    <FormGroup style={{ margin: 0 }}>
                      <Label for="totalCredit">Total Credit</Label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                        <MoneyInput
                          minimum={0}
                          disabled
                          value={user.totalCredit}
                        />
                        <InputGroupAddon addonType="append">
                          USD
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col
                    xs={{
                      size: 12,
                    }}
                  >
                    <Field
                      name="email"
                      label="Email"
                      type="email"
                      placeholder="Agent Email..."
                    />
                  </Col>
                  <Col xs={4}>
                    <Field
                      name="phone"
                      label="Phone"
                      placeholder="Agent Phone..."
                    />
                  </Col>
                  {user.marketplace === 'sonic' && (
                    <Col
                      xs={{
                        size: 6,
                        offset: 2,
                      }}
                    >
                      <SelectField
                        name="organizationID"
                        label="Organization"
                        valueKey="networkID"
                        labelKey="networkName"
                        options={networkData?.networkMany || []}
                      />
                    </Col>
                  )}
                </CardBody>
              </Card>

              <Card className="mb-3">
                <CardHeader className="d-flex justify-content-between">
                  <CardTitle
                    tag="h5"
                    className="py-2 h4 mb-0 text-uppercase d-flex align-items-center"
                  >
                    Other Credit Balances{' '}
                  </CardTitle>
                </CardHeader>
                <CardBody tag={Row} noGutters>
                  <Row style={{ width: '100%', marginLeft: 10 }}>
                    <Col>
                      {user.otherBrokerCredits.map((brokerCredit) => {
                        const brokerBalance = adminOtherBrokerCredits.find(
                          (b) => {
                            return brokerCredit.brokerID === b.brokerID;
                          }
                        );
                        return (
                          <Col>
                            <Row
                              style={{
                                flex: 1,
                                justifyContent: 'space-between',
                                marginBottom: 20,
                              }}
                            >
                              <Col
                                key={brokerCredit.brokerID}
                                xs={{
                                  size: 5,
                                }}
                              >
                                <FormGroup style={{ margin: 0 }}>
                                  <Label for="totalCredit">
                                    {brokerCredit.brokerName}
                                  </Label>
                                  <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                      $
                                    </InputGroupAddon>
                                    <MoneyInput
                                      minimum={0}
                                      disabled
                                      value={brokerCredit.credits}
                                    />
                                    <InputGroupAddon addonType="append">
                                      USD
                                    </InputGroupAddon>
                                  </InputGroup>
                                  <Label style={{ marginTop: 20 }}>
                                    {' '}
                                    {`Admin ${name} balance: ${formatCurrencyForDisplay(
                                      brokerBalance?.credits || 0
                                    )}`}{' '}
                                  </Label>
                                </FormGroup>
                              </Col>
                              <Col
                                key={`${brokerCredit.brokerID}_transfer`}
                                xs={{
                                  size: 5,
                                }}
                              >
                                <FormGroup style={{ margin: 0 }}>
                                  <Label for="totalCredit">
                                    {`Transfer Funds from ${name}`}
                                  </Label>
                                </FormGroup>
                                <Button
                                  color="success"
                                  onClick={() => {
                                    toggleCreditModal({
                                      broker: {
                                        availibleCredit:
                                          brokerBalance?.credits || 0,
                                        fromAgentID: adminAgentID,
                                        brokerID: brokerCredit.brokerID,
                                        brokerName: brokerCredit.brokerName,
                                        agentID: user.agentID!,
                                        networkID: user.networkID!,
                                      },
                                    });
                                  }}
                                >
                                  Transfer Credit
                                </Button>
                              </Col>
                            </Row>
                            <hr />
                          </Col>
                        );
                      })}
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card className="mb-3">
                <CardHeader>
                  <CardTitle tag="h5" className="py-2 h4 mb-0 text-uppercase">
                    Network
                  </CardTitle>
                </CardHeader>
                <CardBody tag={Row} noGutters>
                  <Col>
                    <Row>
                      <Col xs={2}>
                        <FormGroup>
                          <Label>Role</Label>
                          <UncontrolledDropdown>
                            <DropdownToggle caret>
                              {roleLabels[values.role]}
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={() => setFieldValue('role', 'user')}
                                active={values.role === 'user'}
                              >
                                User
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => setFieldValue('role', 'manager')}
                                active={values.role === 'manager'}
                              >
                                Manager
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => setFieldValue('role', 'admin')}
                                active={values.role === 'admin'}
                              >
                                Admin
                              </DropdownItem>
                              <DropdownItem
                                onClick={() =>
                                  setFieldValue('role', 'super_admin')
                                }
                                active={values.role === 'super_admin'}
                              >
                                Super Admin
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={{
                          size: 2,
                          offset: 1,
                        }}
                      >
                        <FormikSwitch
                          height={28}
                          width={56}
                          handleDiameter={26}
                          name="agentApproved"
                          className="mt-3"
                          label="Agent Approved"
                          helpMessage="Agents require manager approval before they can use marketplace services."
                        />
                      </Col>
                      <Col xs={7}>
                        <ManagerSelect
                          isMulti
                          mainGroupID={user.mainGroupID}
                          label="Managers"
                          name="managers"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={8}>
                        <PriceTierSelect
                          name="allowedLeadTiers"
                          label="Allowed Price Tiers"
                          placeholder="Select allowed Price Tiers...."
                          defaultLeadTiers={network.allowedLeadTiers}
                          networkID={
                            isAllowedToAssignVendors
                              ? [user.networkID, ...(values.networkIDs || [])]
                              : user.networkID
                          }
                          isMulti
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={3}>
                        <SelectField
                          name="mainGroupID"
                          label="Main Group"
                          labelKey="label"
                          valueKey="value"
                          options={mainGroups}
                        />
                      </Col>
                      <Col xs={5}>
                        <SelectField
                          name="brokers"
                          label="Other Brokers"
                          valueKey="brokerID"
                          labelKey="brokerName"
                          options={
                            network?.brokers?.filter((b) => {
                              return b.brokerID !== network.brokerID;
                            }) || []
                          }
                          isMulti
                        />
                      </Col>
                    </Row>
                  </Col>
                </CardBody>
              </Card>
              {isAllowedToAssignVendors && (
                <Card className="mb-3">
                  <CardHeader>
                    <CardTitle tag="h5" className="py-2 h4 mb-0 text-uppercase">
                      Internal Only
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col xs={6}>
                        <VendorSelect
                          name="allowedVendors"
                          label="Agent Allowed Vendors"
                          placeholder="Network's Allowed Vendors..."
                          additional={{
                            networkID: network?.networkID,
                            ignoreAccessControl: true,
                          }}
                          isMulti
                        />
                      </Col>
                      <Col xs={6}>
                        <SelectField
                          name="networkIDs"
                          label="Other Networks"
                          valueKey="networkID"
                          labelKey="networkName"
                          options={(networkData?.networkMany || []).filter(
                            (n) => n.networkID !== user?.networkID
                          )}
                          isMulti
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              )}
              <Card className="mb-3">
                <CardHeader className="d-flex justify-content-between">
                  <CardTitle
                    tag="h5"
                    className="py-2 h4 mb-0 text-uppercase d-flex align-items-center"
                  >
                    Company Information
                  </CardTitle>
                </CardHeader>
                <CardBody tag={Row} noGutters>
                  <Row>
                    <Col
                      xs={{
                        size: 12,
                      }}
                    >
                      <Field
                        name="agentCorpID"
                        label="Agent Corp ID"
                        placeholder="Agent Corp ID..."
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="mb-3">
                <CardHeader>
                  <CardTitle tag="h5" className="py-2 h4 mb-0 text-uppercase">
                    Agent Flags
                  </CardTitle>
                </CardHeader>
                <CardBody tag={Row} noGutters>
                  <Col xs={3}>
                    <FormikSwitch
                      name="flags.allowBeta"
                      label="Allow Beta"
                      helpMessage="Allow agent access to beta.leadarena.com"
                    />
                  </Col>
                  <Col xs={4}>
                    <FormikSwitch
                      name="flags.allowMarketplaceBrokering"
                      label="Allow Marketplace Brokering"
                      helpMessage="Allow agent to broker leads"
                    />
                  </Col>
                  <Col xs={3}>
                    <FormikSwitch
                      name="flags.allowBrokerRejects"
                      label="Allow Broker Rejects"
                      helpMessage="Allow Agent to broker rejected leads"
                    />
                  </Col>
                  <Col xs={2}>
                    <FormikSwitch
                      name="flags.allowSelfEdit"
                      label="Allow Self Edit"
                      helpMessage="Allow Agent to edit their Profile Information"
                    />
                  </Col>
                  <Col xs={3}>
                    <FormikSwitch
                      name="flags.allowMyAgents"
                      label="Allow My Agents"
                      helpMessage="Allow Agent to view My Agents Page"
                    />
                  </Col>
                  <Col xs={4}>
                    <FormikSwitch
                      name="flags.canDeliverDuplicateLeads"
                      label="Can Deliver Duplicate Leads"
                      helpMessage="Allow Agent to deliver duplicate leads."
                    />
                  </Col>
                  <Col xs={4}>
                    <FormikSwitch
                      name="flags.allowVendors"
                      label="Allow Vendors"
                      helpMessage="Allow Agent to view Vendors Page"
                    />
                  </Col>
                  <Col xs={3}>
                    <FormikSwitch
                      name="flags.allowCustomAPIs"
                      label="Allow Custom APIs"
                      helpMessage="Allow Agent to create custom API integrations"
                    />
                  </Col>
                  <Col xs={3}>
                    <FormikSwitch
                      name="flags.allowBulkSales"
                      label="Allow Bulk Sales of Leads"
                      helpMessage="Allow Agent to see the Bulk Sales Tab in the Marketplace"
                    />
                  </Col>
                  <Col xs={5}>
                    <FormikSwitch
                      name="flags.allowRegisterForOutboundTransfers"
                      label="Allow Register For Outbound Transfers"
                      helpMessage="Allow Register For Outbound Transfers"
                    />
                  </Col>
                  <Col xs={4}>
                    <FormikSwitch
                      name="flags.allowCrossNetworkTransfers"
                      label="Allow Cross Network Transfers"
                      helpMessage="Allow Agent to transfer credit to anyone given they know the agent's email"
                    />
                  </Col>
                  <Col xs={4}>
                    <FormikSwitch
                      name="flags.allowManagerDashboard"
                      label="Allow Manager Dashboard"
                      helpMessage="Allow Agent to see manager dashboard"
                    />
                  </Col>
                  <Col xs={4}>
                    <FormikSwitch
                      name="flags.allowCallerReady"
                      label="Allow Caller Ready"
                      helpMessage="Allow Agent to see Caller Ready Actions"
                    />
                  </Col>
                  <Col xs={4}>
                    <FormikSwitch
                      name="flags.allowSideline"
                      label="Allow Sideline"
                      helpMessage="Allow Agent to use Sideline Intergration"
                    />
                  </Col>
                </CardBody>
              </Card>
              <div className="float-right">
                <Button
                  color="secondary"
                  type="reset"
                  className="mr-2"
                  size="lg"
                >
                  Reset
                </Button>
                <Button color="primary" size="lg" type="submit">
                  Save
                </Button>
              </div>
            </>
          );
        }}
      </Form>
      <ProfileAddCreditModal
        toggle={toggleCreditModal}
        isOpen={creditModal}
        broker={selectedBroker}
      />
    </>
  );
};

export default General;
